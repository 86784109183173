import { useEffect } from 'react';

const MetamaskLogo = () => {
  useEffect(() => {
    const ModelViewer = require('@metamask/logo');

    // To render with fixed dimensions:
    const viewer = ModelViewer({
      //   pxNotRatio: true,
      //   width: 500,
      //     height: 400,
      pxNotRatio: false,
      width: 0.05,
      height: 0.05,
    });

    // add viewer to DOM
    const container = document.getElementById('logo-container');
    while (container?.firstChild) {
      container.removeChild(container.firstChild);
    }
    container?.appendChild(viewer.container);

    // look at something on the page
    viewer.lookAt({
      x: 100,
      y: 100,
    });

    // enable mouse follow
    viewer.setFollowMouse(true);

    // deallocate nicely
    viewer.stopAnimation();
  }, []);

  return (
    <div>
      <div id="logo-container"></div>
    </div>
  );
};

export default MetamaskLogo;
