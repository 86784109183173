import { RefObject, useEffect, useRef, useState } from 'react';
import './chatbox.css';
import Message from './Message';
import ChatHeader from './ChatHeader';
import { get_ticket_comments, update_ticket, update_ticket_image } from '../utils/backend_functions';
import * as DOMPurify from 'dompurify';
import ImageModal from './ImageModal';
import fileImage from '../assets/file_image.png';

export default function ChatBox({
  comments,
  ticketId,
  ticketSubject,
  ticketStatus,
  accountAddress,
  apiKey,
  changeIndexState,
}: any) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [agentName, setAgentName] = useState<string>('Agent');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  const fileInputRef = useRef(null);

  const removeAttachments = () => {
    setSelectedFiles([]);
  };

  const handleFileChange = (e) => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    const newFiles: File[] = Array.from(e.target.files);
    let currentTotalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);

    const validFiles : File[] = [];
    const rejectedFiles : string[] = [];
    for (let file of newFiles) {
      if (currentTotalSize + file.size <= maxSizeInBytes) {
        validFiles.push(file);
        currentTotalSize += file.size;
      } else {
        rejectedFiles.push(file.name);
      }
    }

    if (rejectedFiles.length > 0) {
      alert(`Only 5MB of media can be sent at a time. If you need to send larger files, please use email, or send the files separately if their individual size is less than 5MB. The following files could not be added: \n\n ${rejectedFiles}`);
    }

    const updatedSelectedFiles: File[] = [...selectedFiles, ...validFiles];
    setSelectedFiles(updatedSelectedFiles);

    console.log(selectedFiles);

    // Clear the file input to allow selecting the same file again
    fileInputRef.current.value = null;

    validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (file.type.startsWith('image/')) {
            file.preview = reader.result;
          }
          else if (file.type.startsWith('application/')) {
            file.preview = file.name;
          }
          setSelectedFiles([...updatedSelectedFiles]); // Update state to trigger a re-render
        };
        reader.readAsDataURL(file);
    });
  };


  const fetchTicketComments = async () => {
    get_ticket_comments(ticketId, accountAddress, apiKey).then((comments) => {
      if (comments) {
        setMessages(comments);
      }
    });
  };


  const sendTextMessage = async () => {
    if (newMessage.trim() === '' || newMessage.trim() === 'sending message...')
      return;
    const input_value = DOMPurify.sanitize(newMessage);
    setNewMessage('sending message...');
    setIsLoading(true);
    await update_ticket(ticketId, input_value, accountAddress, apiKey);
    await fetchTicketComments();
    setIsLoading(false);
    setNewMessage('');
    changeIndexState(ticketId);
  };

  const sendMultimediaMessage = async () => {
    await setIsLoading(true);
    let input_value = DOMPurify.sanitize(newMessage);
    setNewMessage('sending message...');
    let imagesData = await Promise.all(
      selectedFiles.map(async (file) => {
        if (file.type.startsWith('image/') || file.type === 'application/pdf'
          || file.type === 'application/json' || file.type === 'application/msword'
          || file.type === 'text/plain'
          || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          const reader = new FileReader();
          const imageDataPromise = new Promise<string>((resolve) => {
            reader.onloadend = () => {
              resolve((reader.result as string).split(',')[1]); // Extract base64 data
            };
          });
  
          reader.readAsDataURL(file);
          const imageData = await imageDataPromise;
          return { imageData, filename: file.name };
        }
        else {
          alert(`Unsupported format: ${file.type} - Allowed files are: images, txt, json, pdf and doc(x).`)
          removeAttachments();
        }
        return null;
      })
    );
  
    if (input_value === 'sending message...')
      input_value = "(no description)"

    imagesData = imagesData.filter((data) => data !== null);
    await update_ticket_image(ticketId, input_value, imagesData, accountAddress, apiKey);
    await fetchTicketComments();
    removeAttachments();
    setIsLoading(false);
    setNewMessage('');
    changeIndexState(ticketId);
  };

  const handleSendMessage = async () => {
    if (selectedFiles.length === 0) {
      sendTextMessage();
    }
    else {
      sendMultimediaMessage();
    }
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && isLoading === false) {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (comments) {
      setMessages(comments);
    }
  }, [ticketId, comments]);

  useEffect(() => {
    // console.log('useEffect 2 called');
    scrollToBottom();
  }, [messages]);


  return (
    <div className="chat-box">
      <ChatHeader ticketId={ticketId} ticketTitle={ticketSubject} />
      <div ref={containerRef} className="messages">
        {messages &&
          messages.map((comment: any, index: any) => {
            // first comment is labeled Description
            let sender = (comment.via.channel === 'api' || comment.via.channel === 'email') ? 'You' : agentName;
            if (index == 0) sender = 'Description';
            return (
              <Message
                key={index}
                text={comment.body}
                sender={sender}
                created_at={comment.created_at}
                attachments={comment.attachments}
              />
            );
          })}
      </div>
      {selectedFiles.length > 0 && (
        <div className='attachments-preview-container'>
          <label className="remove-attachments-label" onClick={removeAttachments}>
            Remove all
          </label>
            {selectedFiles.map((file, index) => (
              <div key={index} className='attachment-preview'>
                {
                  file.type.startsWith('image/') ? (
                    <img
                      src={DOMPurify.sanitize(file.preview)}
                      alt={`Preview of ${file.name}`}
                      onClick={() => setSelectedFile(file.preview)}
                    />
                  )
                    :
                  // <div className='application-attachment-box'> {file.name} </div>
                  <div key={index} className='doc-render'>
                      <img src={fileImage} alt="Document" />
                      <p>{file.name}</p>
                  </div>
                }
              </div>
            ))}
        </div>
      )}
      { selectedFile && (<ImageModal imageUrl={selectedFile} onClose={() => setSelectedFile(null)} />) }
      {ticketStatus !== "closed" ? <div className="input-box">
      <label className="attachment-label">
        Attachments 📎
        <input multiple type="file" className='attachment-input' ref={fileInputRef}
        onChange={handleFileChange} />
      </label>
        <input
          className="input-text"
          type="text"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyUp={handleKeyPress}
          readOnly={isLoading}
        />
        {/* <button onClick={handleTest}>TEST</button> */}
        <button className='send-message-button' onClick={handleSendMessage} disabled={isLoading}>Send message</button>
      </div>
        :
        <div className='input-box'>
          <p>
          You can no longer reply to this ticket, since it was closed. Feel free to open a new ticket if you need help.
          </p>
        </div>
      }
    </div>
  );
}
