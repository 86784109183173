import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { defaultSnapOrigin } from '../config';
import './Modal.css';
import pkg from 'crypto-js';

function NotificationsModal({ show, onClose, apiKey, address, apiExpiry }: any) {
  if (!show) return null;

  const [selectedOption, setSelectedOption] = useState('dialog');

  const { SHA256 } = pkg;

  // obfuscating the address
  const address_key = SHA256(address, { outputLength: 32 }).toString();

  const set_snap_dialog = (dialog: boolean) => {
    const url =
      'https://71z6182pq3.execute-api.eu-west-1.amazonaws.com/default/settings';

    const options = {
      method: 'POST',
      headers: {
        Uid: address_key,
        Authorization: apiKey,
      },
      body: JSON.stringify({
        dialog: dialog
      }),
    };

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        console.log('Sent notification settings to snap: ', data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOptionChange = (e: any) => {
    setSelectedOption(e.target.value);
  };

  const handleConfirm = async () => {
    onClose(); // closes the modal
    const dialog = selectedOption === 'dialog' ? true : false;
    const dialog_string = selectedOption === 'dialog' ? 'true' : 'false';
    set_snap_dialog(dialog);

    await window.ethereum.request({
      method: 'wallet_invokeSnap',
      params: {
        snapId: defaultSnapOrigin,
        request: {
          method: 'set_snap_state',
          params: { apiKey, address, dialog: dialog_string, apiExpiry },
        },
      },
    });
  };

  const fetchNotificationSettings = async () => {
    const resp = await fetch(
      `https://71z6182pq3.execute-api.eu-west-1.amazonaws.com/default/settings`,
      {
        method: 'GET',
        headers: {
          Uid: address_key,
          Authorization: apiKey,
        },
      },
    );
    const dialog = JSON.stringify(await resp.json());
    if (dialog === 'true') setSelectedOption('dialog');
    else setSelectedOption('pop-up');
  };

  // useEffect care face getSnapState si seteaza stateul aici
  useEffect(() => {
    fetchNotificationSettings();
  }, []);

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={(event) => event.stopPropagation()}>
        <div className='modal-header'>
          Metamask notifications? Yes please!
        </div>
        <div className='modal-contents'>
          <p>
            We've got you covered with two types of notifications for ticket
            updates: Metamask Snaps dialog box notification, that allows you to
            also reply to the ticket directly, and the good old browser pop-up
            notifications, if you like'em less intrusive. Select the option that
            suits your notification style:
          </p>
          <div className="options">
            <label>
              <input
                type="radio"
                value="dialog"
                checked={selectedOption === 'dialog'}
                onChange={handleOptionChange}
              />
              Dialog box notification
            </label>
            <label>
              <input
                type="radio"
                value="pop-up"
                checked={selectedOption === 'pop-up'}
                onChange={handleOptionChange}
              />
              Browser pop-up notification
            </label>
          </div>
        </div>
        <button className="close-buttonas" onClick={handleConfirm}>
          Confirm
        </button>
      </div>
    </div>,
    document.getElementById('modal-root')!,
  );
}

export default NotificationsModal;
