import React, { useEffect } from 'react';
import './chatbox.css';

const ImageModal = ({ imageUrl, onClose }) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            onClose();
        }
    };
    
    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
    
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
    
    return (
        <div className="image-modal" onClick={onClose}>
            <div className="modal-content">
                <img src={imageUrl} alt="Large Image" />
            </div>
            <button className="close-button" onClick={onClose}>
                X
            </button>
        </div>
    );
};

export default ImageModal;