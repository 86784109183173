import { useContext, useEffect, useRef, useState } from 'react';
import {
  get_user_tickets,
  get_ticket_comments,
} from '../utils/backend_functions';
import styled from 'styled-components';
import { MetamaskActions, MetaMaskContext } from '../hooks';
import ConversationCard from '../components/ConversationCard';
import ChatBox from '../components/ChatBox';
import NotificationsModal from '../components/NotificationsModal';
import {
  connectSnap,
  isLocalSnap,
  getSnap,
  shouldDisplayReconnectButton,
  signInWithEthereum,
} from '../utils';
import {
  ConnectButton,
  InstallFlaskButton,
  ReconnectButton,
  Card,
  Button,
  Header,
} from '../components';
import { useIsSignedIn } from '../hooks/useIsSignedIn';
import { useSnapRequest } from '../hooks/useSnapRequest';
import { ToggleThemeContext } from '../Root';
import { defaultSnapOrigin } from '../config';
import React from 'react';
import LogoutModal from '../components/LogoutModal';
import MetamaskLogo from '../components/MetamaskLogo';
import LoadingWheel from '../components/LoadingWheel';
import './index_page.css'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 5.5rem;
  margin-bottom: 15.6rem;
  ${({ theme }) => theme.mediaQueries.small} {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: auto;
  }
`;

const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: 2.4rem;
  text-align: center;
`;

const Span = styled.span`
  color: ${(props) => props.theme.colors.primary.default};
`;

const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  ${({ theme }) => theme.mediaQueries.small} {
    font-size: ${({ theme }) => theme.fontSizes.text};
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 64.8rem;
  width: 100%;
  height: 100%;
  margin-top: 1.5rem;
`;

const Notice = styled.div`
  background-color: ${({ theme }) => theme.colors.background.alternative};
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  color: ${({ theme }) => theme.colors.text.alternative};
  border-radius: ${({ theme }) => theme.radii.default};
  padding: 2.4rem;
  max-width: 60rem;
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 50px;

  & > * {
    margin: 0;
  }
  ${({ theme }) => theme.mediaQueries.small} {
    margin-top: 1.2rem;
    padding: 1.6rem;
  }
`;

const ErrorMessage = styled.div`
  background-color: ${({ theme }) => theme.colors.error.muted};
  border: 1px solid ${({ theme }) => theme.colors.error.default};
  color: ${({ theme }) => theme.colors.error.alternative};
  border-radius: ${({ theme }) => theme.radii.default};
  padding: 2.4rem;
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;
  max-width: 60rem;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.small} {
    padding: 1.6rem;
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    max-width: 100%;
  }
`;

const Index = () => {
  const [state, dispatch] = useContext(MetaMaskContext);
  const [isConnected, setIsConnected] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [apiExpiry, setApiExpiry] = useState('');
  const [accountAddress, setAccountAddress] = useState('');
  const [showTickets, setShowTickets] = useState(false);
  const [userTickets, setUserTickets] = useState([]);
  const [viewTicket, setViewTicket] = useState(false);
  const [ticketComments, setTicketComments] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [selectedTicketSubject, setSelectedTicketSubject] = useState('');
  const [selectedTicketStatus, setSelectedTicketStatus] = useState('');
  const [ticketWasUpdated, setTicketWasUpdated] = useState(''); // contains ticketId
  const [showModal, setShowModal] = useState(false);
  const [showLogoutModal, setLogoutModal] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(true);
  const toggleTheme = useContext(ToggleThemeContext);
  const { isSignedIn, refreshIsSignedIn } = useIsSignedIn();

  const isMetaMaskReady = isLocalSnap(defaultSnapOrigin)
    ? state.isFlask
    : state.snapsDetected;

  const handleConnectClick = async () => {
    try {
      await connectSnap();
      const installedSnap = await getSnap();
      dispatch({
        type: MetamaskActions.SetInstalled,
        payload: installedSnap,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: MetamaskActions.SetError, payload: e });
    }
  };

  const signInHandler = async () => {
    try {
      const [api_key, address, apiExpiry] = await signInWithEthereum();
      refreshIsSignedIn();
      setApiKey(api_key);
      setApiExpiry(apiExpiry);
      setAccountAddress(address);
      setIsConnected(true);
    } catch (e) {
      console.error(e);
      dispatch({ type: MetamaskActions.SetError, payload: e });
    }
  };

  const {
    result: requestResult,
    error: requestError,
    isLoading: isLoadingResult,
    makeRequest: makeSnapRequest,
  } = useSnapRequest();

  const refreshTicketsList = async () => {
    console.log('Fetching tickets for ', accountAddress);
    await get_user_tickets(accountAddress, apiKey).then((json) => {
      if (json == null || json == undefined) {
        console.log('No tickets could be found. Retrying soon...');
      } else {
        if (json['rows']) setUserTickets(json['rows']);
      }
    });
  };

  const handleViewTicket = async (ticket_id: any, subject: any, ticket_status: any) => {
    setViewTicket(false);
    setShowInitialMessage(false);
    setSelectedTicketId(ticket_id);
    setSelectedTicketSubject(subject);
    setSelectedTicketStatus(ticket_status);
    const ticket_comments = await get_ticket_comments(
      ticket_id,
      accountAddress,
      apiKey,
    );
    setViewTicket(true);
    // console.log(ticket_comments);
    if (ticket_comments != null) setTicketComments(ticket_comments);
  };

  const handleLogout = (logout: boolean) => {
    setLogoutModal(false);
    if (logout) {
      setApiKey('');
      setApiExpiry('');
      setAccountAddress('');
      setIsConnected(false);
      setSelectedTicketId(null);
      setViewTicket(false);
      setShowInitialMessage(true);
      setSelectedTicketSubject('');
    }
    // update snap state here if we want notifications to stop firing
  };

  const fetchTicketComments = async () => {
    // console.log([apiKey, accountAddress, selectedTicketId]);
    console.log('Fetching comments for ticket with id ', selectedTicketId);
    get_ticket_comments(selectedTicketId, accountAddress, apiKey).then(
      (comments) => {
        if (comments != null) {
          setTicketComments(comments);
        }
      },
    );
  };

  // updates the state when the user posts a comment on a ticket
  // used for auto changing colour of ticket card title
  // USELESS right now, should delete & refactor code
  const callback_updatedTicket = (updatedticketId: any) => {
    setTicketWasUpdated(updatedticketId);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleLogoutModal = () => {
    setLogoutModal(!showLogoutModal);
  };

  useEffect(() => {
    if (isConnected) {
      refreshTicketsList();
      const intervalId = setInterval(refreshTicketsList, 60000);
      console.log('Refreshing tickets list');
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isConnected]);

  // polling for new ticket comments whenever a new ticket is selected from the ticket list
  // and every 30 seconds for the current ticket updates
  useEffect(() => {
    if (selectedTicketId) {
      const intervalId = setInterval(fetchTicketComments, 30000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [selectedTicketId]);

  let conversations_list = null;
  if (userTickets) {
    // console.log(userTickets);
    conversations_list =
      userTickets.length > 0 ? (
        userTickets.map((ticket) => (
          <div
            key={ticket['ticket']['id']}
            onClick={() =>
              handleViewTicket(
                ticket['ticket']['id'],
                ticket['ticket']['subject'],
                ticket['ticket']['status']
              )
            }
          >
            <ConversationCard
              id={ticket['ticket']['id']}
              title={ticket['ticket']['subject']}
              ticket_status={ticket['ticket']['status']}
              created={ticket['created']}
            />
          </div>
        ))
      ) : (
        <div>Your conversations will appear here</div>
      );
  }
  const initial_message = (
    <div className='initial-message'>
      <div className="initial-card">
        <div className='initial-card-header'>
          {' '}
          <MetamaskLogo />
          Protect your funds <br></br>
        </div>
        <p className='initial-card-p'>
          Remember that your secret recovery phrase controls all of your
          accounts. Keep in mind:
        </p>
        <ul className='initial-card-ul'>
          <li>
            Cautiously read the entirety of any message you are prompted to sign
            with MetaMask.
          </li>
          <li>
            <b>Never</b> sign a message if you don't fully understand its
            content.
          </li>
          <li>
            {' '}
            <b>Never</b> share your secret recovery phrase with anyone.
          </li>
          <li>
            {' '}
            Metamask support will <b>never</b> ask for your secret recovery
            phrase.
          </li>
          <li>
            {' '}
            Need to back-up your secret recovery phrase?{' '}
            <a
              href="https://support.metamask.io/hc/en-us/articles/360015290032-How-to-reveal-your-Secret-Recovery-Phrase"
              target="_blank"
            >
              Start here
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  const frontpage_message = (
    <div className='front-page-message'>
      <div className="initial-card">
        <div className='initial-card-header'>
          <MetamaskLogo />
          Protect your funds <br></br>
        </div>
        <p className='initial-card-p'>
          To sign into the dashboard you will be prompted to sign a clear text
          message to prove that you are the owner of the MetaMask account.
        </p>
        <ul className='initial-card-ul'>
          <li>
            Cautiously read the entirety of any message you are prompted to sign
            with MetaMask.
          </li>
          <li>
            <b>Never</b> sign a message if you don't fully understand its
            content.
          </li>
          <li>
            {' '}
            <b>Never</b> share your secret recovery phrase with anyone.
          </li>
          <li>
            {' '}
            Metamask support will <b>never</b> ask for your secret recovery
            phrase.
          </li>
          <li>
            {' '}
            Need to back-up your secret recovery phrase?{' '}
            <a
              href="https://support.metamask.io/hc/en-us/articles/360015290032-How-to-reveal-your-Secret-Recovery-Phrase"
              target="_blank"
            >
              Start here
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div>
      {isConnected && (
        <Header
          handleSettingsClick={toggleModal}
          handleToggleClick={toggleTheme}
          handleLogoutClick={toggleLogoutModal}
          address={accountAddress}
        />
      )}
      <div id="modal-root">
        <NotificationsModal
          show={showModal}
          onClose={() => setShowModal(false)}
          apiKey={apiKey}
          apiExpiry={apiExpiry}
          address={accountAddress}
        />
      </div>
      <div id="modal-logout">
        <LogoutModal show={showLogoutModal} onClose={handleLogout} />
      </div>
      {!isConnected && (
        <Notice >
          Double check that the URL is <b>https://tickets.metamask.io</b> before
          proceeding.
        </Notice>
      )}
      {!isConnected && (
        <Container>
          {!isConnected && (
            <Heading>
              Welcome to the ✨<Span>Web3 Ticketing experience</Span>✨
            </Heading>
          )}
          <div>
            Don't know what this is? Check out our{' '}
            <a
              target="_blank"
              href="https://support.metamask.io/hc/en-us/articles/22053676917915-Getting-started-with-the-Web3-Ticket-Dashboard"
            >
              FAQ page
            </a>
            .
          </div>

          {state.error && (
            <ErrorMessage>
              <b>An error happened:</b> {state.error.message}
            </ErrorMessage>
          )}

          {!state.installedSnap && (
            <div className='install-snap-card'>
              <Card
                content={{
                  title: 'Connect',
                  description:
                    'Get started by connecting to and installing the Web3 Ticketing snap.',
                  button: (
                    <ConnectButton
                      onClick={handleConnectClick}
                      disabled={!isMetaMaskReady}
                    />
                  ),
                }}
                disabled={!isMetaMaskReady}
              />
            </div>
          )}

          {!isConnected && (
            <div className='siwe-card'>
              <Card
                content={{
                  title: 'Sign-in with Ethereum',
                  description:
                    'Sign-in with Ethereum to access your conversations dashboard.',
                  button: (
                    <Button
                      onClick={signInHandler}
                      disabled={!state.installedSnap}
                    >
                      Sign-in
                    </Button>
                  ),
                }}
                disabled={!state.installedSnap}
              />
            </div>
          )}
          {!isConnected && frontpage_message}
        </Container>
      )}
      {apiKey !== '' && accountAddress !== '' && apiExpiry !== '' && (
        <div>
          <div className='inline-style-container'>
            <div className='conversations-list'>{conversations_list}</div>
            <div className='chat-box-container'>
              {viewTicket ? (
                <ChatBox
                  comments={ticketComments}
                  ticketId={selectedTicketId}
                  ticketSubject={selectedTicketSubject}
                  ticketStatus={selectedTicketStatus}
                  accountAddress={accountAddress}
                  apiKey={apiKey}
                  apiExpiry={apiExpiry}
                  changeIndexState={callback_updatedTicket}
                />
              ) : (
                showInitialMessage && initial_message
              )}
              {!viewTicket && !showInitialMessage && <LoadingWheel />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
