import React, { useState, useEffect } from 'react';
import './Spinner.css';

const LoadingWheel = () => {
  return (
    <div>
      <div
        className="spinner"
      />
      <div>Loading...</div>
    </div>
  );
};

export default LoadingWheel;
