import Linkify from 'linkify-react';
import './chatbox.css'
import React, { useState } from 'react';
import ImageModal from './ImageModal';
import * as ReactDOM from 'react-dom';
import * as DOMPurify from 'dompurify';
import fileImage from '../assets/file_image.png';

function Message({ text, sender, created_at, attachments }: any) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };
  
  const messageStyle = sender === 'Agent' ? 'left' : 'right';
  const timestamp =
    'Sent at ' +
    created_at.substring(11, 16) +
    ' UTC on ' +
    created_at.substring(0, 10);
  
    const renderContent = (content) => {
      const parts = content.split(/!\[\]\((.*?)\)/);
      return parts.map((part, index) => {
        if (index % 2 === 0) {
          // Plain text
          return <span key={index}>{part}</span>;
        } else {
          // Attachment
          return <div><img key={index} src={DOMPurify.sanitize(part)} alt="Attachment"
            onClick={() => handleImageClick(DOMPurify.sanitize(part))} className='zendesk-inline-img' /></div>;
        }
      });
    };
  
  // console.log(attachments);

  return (
    <div className={`message ${messageStyle}`}>
      <div className={`message-sender ${messageStyle}`}>{sender}:</div>
      <div className="message-text">
        {messageStyle === 'left' ? <Linkify>{renderContent(text)}</Linkify> : <Linkify>{DOMPurify.sanitize(text)}</Linkify>}
      </div>
      {attachments.length > 0 &&
        <div className='message-attachments-container'>
          {
            attachments.map((attachment: any, index: any) => {
              if (attachment.content_type.startsWith('image')) {
                return (
                  <img key={index} src={DOMPurify.sanitize(attachment.content_url)} onClick={() => handleImageClick(DOMPurify.sanitize(attachment.content_url))} />
                );
              } else {
                  return <div key={index} className='doc-render'>
                      <img src={fileImage} alt="Document" />
                      <p>{attachment.file_name}</p>
                  </div>
                }
              })
          }
        </div>
      }
      <div className={`message-timestamp ${messageStyle}`}>{timestamp}</div>
      {selectedImage &&
        ReactDOM.createPortal(
          <ImageModal imageUrl={selectedImage} onClose={handleCloseModal} />,
          document.body
        )}
    </div>
  );
}

export default Message;
