import './chatbox.css'

const ChatHeader = ({ ticketId, ticketTitle }: any) => {
  return (
    <div className="chat-header">
      <div>Conversation #{ ticketId } | {ticketTitle}</div>
    </div>
  );
};

export default ChatHeader;
