import './chatbox.css';

export default function ConversationCard({
  id,
  title,
  ticket_status,
  created,
}: {
  id: any;
  title: string;
  ticket_status: any;
  created: any;
}) {
  const words = title.split(' ');
  const splitted_date = created.split('T');
  const date = splitted_date[0];
  const time = splitted_date[1].slice(0, 5);
  let displayed_title = words.slice(0, 8).join(' ');
  let displayed_status = 'Solved';
  let colorClass = 'card-header-solved';

  if (words.length > 8) displayed_title += ' ...';

  if (
    ticket_status === 'open' ||
    ticket_status === 'new' ||
    ticket_status === 'hold'
  ) {
    displayed_status = "Waiting support's reply";
    colorClass = 'card-header-open';
  } else if (ticket_status === 'pending') {
    displayed_status = 'Waiting for your reply';
    colorClass = 'card-header-pending';
  }

  return (
    <div className="card">
      <div
        className={`card-header ${colorClass}`}
      >
        <b>{id}</b> | {displayed_status}
      </div>
      <div className="card-body">
        {displayed_title}
      </div>
      <div className="card-footer">
        <div className="footer-date">{date}</div>
        <div className="footer-time">{time} UTC</div>
      </div>
    </div>
  );
}
