import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './Modal.css';

function LogoutModal({ show, onClose }: any) {
  if (!show) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={() => onClose(false)}>
      <div className="modal" onClick={(event) => event.stopPropagation()}>
        <div className = "modal-header">
          Logging out?
        </div>
        <p>
          Metamask or browser notifications will still fire as long as you
          keep the browser open.
        </p>
        <div className="buttons-container">
          <button className="cancel-buttonas" onClick={() => onClose(false)}>
            Cancel
          </button>
          <button className="close-buttonas" onClick={() => onClose(true)}>
            Log out
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')!,
  );
}

export default LogoutModal;
